















import BaseModel from '../../application/model/BaseModel'
import { Component } from 'vue-property-decorator'

@Component({})
export default class Settings extends BaseModel {
  constructor () {
    super('settings')
  }
}
